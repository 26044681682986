import { FunctionComponent } from 'react';

import { IS_APP_QOLIBRI } from '../../application';
import edolLogo from '../../assets/Logo_eDOL_V3.png';
import qoLibriLogo from '../../assets/Logo_QoLibri.png';

export const APPLICATION_LOGO_TEST_ID = 'application-logo-test-id';

export const ApplicationLogo: FunctionComponent = () => {
  return (
    <img
      src={IS_APP_QOLIBRI ? qoLibriLogo : edolLogo}
      alt="application logo"
      height={45}
      width={118}
      data-testid={APPLICATION_LOGO_TEST_ID}
    />
  );
};

import './LegalNoticeConsent.scss';

import { AppShell } from '@mantine/core';
import type {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IS_APP_QOLIBRI } from '../../application';
import eDolLogo from '../../assets/Logo_eDOL_V3.png';
import qoLibriLogo from '../../assets/Logo_QoLibri.png';
import {
  Button,
  ButtonClass,
  Checkbox,
  SnackBar,
  SnackBarType,
} from '../../components';
import { FastEmail } from '../../components/icons/FastEmail';
import { useLegalNoticeConsentUpdate } from '../../services/api/hcp';
import { COLORS, ThemeColor } from '../../theme/colors';
import { LegalMentionConsent } from '../../types/hcp';

interface LegalNoticeConsentProps {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<LegalMentionConsent, unknown>>;
}

export const LegalNoticeConsent: FunctionComponent<LegalNoticeConsentProps> = ({
  refetch,
}) => {
  const { t } = useTranslation();
  const pageId = 'legal-notice-consent';

  const [isTermsOfUseChecked, setIsTermsOfUseChecked] = useState(false);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [isBackendError, setIsBackendError] = useState(false);

  const updateIsPrivacyPolicyChecked = () =>
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);

  const updateIsTermsOfUseChecked = () =>
    setIsTermsOfUseChecked(!isTermsOfUseChecked);

  const onUpdateConsentSuccess = () => {
    refetch();
    setIsBackendError(false);
  };

  const onUpdateConsentError = () => setIsBackendError(true);

  const {
    mutate: mutationLegalNoticeConsentUpdate,
    isLoading: isLoadingLegalNoticeConsentUpdate,
  } = useLegalNoticeConsentUpdate(onUpdateConsentSuccess, onUpdateConsentError);

  const logoProps = IS_APP_QOLIBRI
    ? {
        src: qoLibriLogo,
        height: 48,
        width: 126,
      }
    : {
        src: eDolLogo,
        height: 32,
        width: 84,
      };

  const termsOfUseCheckbox = (
    <div className={`${pageId}-checkbox`}>
      <Checkbox
        color={ThemeColor.primaryBlue}
        onChange={updateIsTermsOfUseChecked}
        data-testid={`${pageId}-terms-of-use-checkbox`}
      />
      <div className={`${pageId}-checkbox-text-container`}>
        <div className={`${pageId}-checkbox-text-separator`}>
          <p className={`${pageId}-checkbox-text`}>
            {t('legalNotice.readAndAcceptedForTermsOfUse')}
          </p>
          <a
            href={import.meta.env.VITE_TERMS_OF_USE_LINK}
            target="_blank"
            rel="noreferrer"
            className={`${pageId}-link`}
            data-testid={`${pageId}-link-to-terms-of-use`}
          >
            {t('legalNotice.termsOfUse')}
          </a>
        </div>
        <p className={`${pageId}-checkbox-text`}>.</p>
      </div>
    </div>
  );

  const privacyPolicyCheckbox = (
    <div className={`${pageId}-checkbox`}>
      <Checkbox
        color={ThemeColor.primaryBlue}
        onChange={updateIsPrivacyPolicyChecked}
        data-testid={`${pageId}-privacy-policy-checkbox`}
      />
      <div className={`${pageId}-checkbox-text-container`}>
        <div className={`${pageId}-checkbox-text-separator`}>
          <p className={`${pageId}-checkbox-text`}>
            {t('legalNotice.readAndAcceptedForPrivacyPolicy')}
          </p>
          <a
            href={import.meta.env.VITE_PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noreferrer"
            className={`${pageId}-link`}
            data-testid={`${pageId}-link-to-privacy-policy`}
          >
            {t('legalNotice.privacyPolicy')}
          </a>
        </div>

        <p className={`${pageId}-checkbox-text`}>.</p>
      </div>
    </div>
  );

  const updateLegalNoticeConsent = () => {
    setIsBackendError(false);
    mutationLegalNoticeConsentUpdate();
  };

  const continueButton = (
    <Button
      buttonClass={ButtonClass.PRIMARY}
      text={t('legalNotice.continue')}
      onClick={updateLegalNoticeConsent}
      disabled={!(isTermsOfUseChecked && isPrivacyPolicyChecked)}
      data-testid={`${pageId}-continue-button`}
      isLoading={isLoadingLegalNoticeConsentUpdate}
    />
  );

  const contact = (
    <div className={`${pageId}-contact-container`}>
      <p className={`${pageId}-contact-text`}>{t('legalNotice.question')}</p>
      <a
        className={`${pageId}-contact-link`}
        href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
        data-testid={`${pageId}-contact-link`}
      >
        {t('legalNotice.ContactUs')}
      </a>
      <FastEmail color={COLORS.primary._500} />
    </div>
  );

  const errorSnackbar = (
    <SnackBar
      title={t('errors.unknownError.message')}
      snackBarType={SnackBarType.ERROR}
      className={`${pageId}-error-snack-bar`}
    />
  );

  return (
    <AppShell padding={0}>
      <div className={`${pageId}-container`} data-testtestI={pageId}>
        {isBackendError && errorSnackbar}
        <div className={`${pageId} shadow-strong`}>
          <div className={`${pageId}-presentation-container`}>
            <div className={`${pageId}-title-container`}>
              <h1 className="h1">{t('legalNotice.title')}</h1>
              <img alt="App logo" {...logoProps} />
            </div>
            <p className="p1">{t('legalNotice.subtitle')}</p>
          </div>
          <div className={`${pageId}-content-container`}>
            <div className={`${pageId}-checkbox-container`}>
              {termsOfUseCheckbox}
              {privacyPolicyCheckbox}
            </div>
            <div className={`${pageId}-button-and-contact-container`}>
              {continueButton}
              {contact}
            </div>
          </div>
        </div>
      </div>
    </AppShell>
  );
};

import { useMutation, useQuery } from '@tanstack/react-query';

import { Hcp, LegalMentionConsent } from '../../types/hcp';
import { ApiClient, ENDPOINTS, HttpMethod } from './ApiClient';

const getDoctors = async (): Promise<Hcp[]> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.GET, ENDPOINTS.DOCTOR);
};

export const useDoctors = () => useQuery<Hcp[]>(['Hcp'], getDoctors);

const updateLegalNoticeConsent = async (): Promise<void> => {
  const apiClient = ApiClient.getInstance();

  return apiClient.request(HttpMethod.PUT, ENDPOINTS.LEGAL_NOTICE);
};

export const useLegalNoticeConsentUpdate = (
  onSuccess: () => void,
  onError?: (error: unknown) => void,
) => {
  return useMutation<void, unknown, void, unknown>(
    ['legalNoticeConsentUpdate'],
    updateLegalNoticeConsent,
    {
      onSuccess,
      onError,
    },
  );
};

const getHasHcpConsentedToLegalNotice =
  async (): Promise<LegalMentionConsent> => {
    const apiClient = ApiClient.getInstance();

    return apiClient.request(HttpMethod.GET, ENDPOINTS.LEGAL_NOTICE);
  };

export const useHasHcpConsentedToLegalNotice = () =>
  useQuery<LegalMentionConsent>(
    ['hasLegalNoticeBeenAccepted'],
    getHasHcpConsentedToLegalNotice,
  );

import './AddPatientModal.scss';

import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IS_APP_QOLIBRI } from '../../../../application';
import {
  ActivityIndicator,
  ArrowRight,
  Button,
  ButtonClass,
  ErrorApiMessage,
  Modal,
  ModalProps,
} from '../../../../components';
import { useDoctors } from '../../../../services/api/hcp';
import { useNewPatient } from '../../../../services/api/patient';
import { PatientApplication, PatientDto, Sex } from '../../../../types/patient';
import { toInteger } from '../../../../utils';
import { BirthYearInput } from './AddPatientModalFields/BirthYearInput';
import { EmailInput } from './AddPatientModalFields/EmailInput';
import { FirstNameInput } from './AddPatientModalFields/FirstNameInput';
import { FirstVisiteDateInput } from './AddPatientModalFields/FirstVisiteDateInput';
import { LastNameInput } from './AddPatientModalFields/LastNameInput';
import { ReferringDoctorInput } from './AddPatientModalFields/ReferringDoctorInput';
import { SexInput } from './AddPatientModalFields/SexInput';
import { SocialSecurityNumberInput } from './AddPatientModalFields/SocialSecurityNumberInput';

interface AddPatientModalProps extends Omit<ModalProps, 'header'> {}

export interface AddPatientForm {
  lastName: string;
  firstName: string;
  sex: Sex;
  birthYear: string;
  socialSecurityNumber: string;
  email: string;
  firstVisitDate: Date;
  referringDoctorId: string;
}

export const AddPatientModal: FunctionComponent<AddPatientModalProps> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation();

  const [isBackendError, setIsBackendError] = useState<boolean>(false);

  const {
    control,
    formState: { isValid },
    reset,
    handleSubmit,
  } = useForm<AddPatientForm>({
    mode: 'onBlur',
    defaultValues: {
      firstVisitDate: new Date(),
    },
  });

  const {
    isLoading: isLoadingDoctors,
    data: doctors,
    isError: isErrorFetchingDoctors,
  } = useDoctors();
  const testId = 'add-patient-modal';

  const patientApplication = IS_APP_QOLIBRI
    ? PatientApplication.QOLIBRI
    : PatientApplication.EDOL;

  const { mutate: newPatientMutate, isLoading: isLoadingNewPatient } =
    useNewPatient(
      () => {
        reset();
        onClose();
      },
      () => setIsBackendError(true),
    );

  const lastnameInput = <LastNameInput control={control} testId={testId} />;

  const firstnameInput = <FirstNameInput control={control} testId={testId} />;

  const sexInput = <SexInput control={control} testId={testId} />;

  const birthYearInput = <BirthYearInput control={control} testId={testId} />;

  const socialSecurityNumberInput = (
    <SocialSecurityNumberInput control={control} testId={testId} />
  );

  const emailInput = <EmailInput control={control} testId={testId} />;

  const firstVisitDateInput = (
    <FirstVisiteDateInput control={control} testId={testId} />
  );
  const referringDoctorInput = (
    <ReferringDoctorInput
      control={control}
      testId={testId}
      doctors={doctors ?? []}
    />
  );

  const saveButton = (
    <Button
      buttonClass={ButtonClass.PRIMARY}
      text={t('save')}
      onClick={() => {
        setIsBackendError(false);
        handleSubmit((data) => {
          const birthYear = toInteger(data.birthYear);
          if (birthYear === null) return; // this will not occur

          const formattedData: PatientDto = {
            ...data,
            firstVisitDate: data.firstVisitDate.toISOString(),
            birthYear,
            application: patientApplication,
          };
          newPatientMutate(formattedData);
        })();
      }}
      disabled={!isValid}
      RightIcon={ArrowRight}
      className="add-patient-save-button"
      data-testid={`${testId}-save-button`}
      isLoading={isLoadingNewPatient}
    />
  );

  const errorMessage = (
    <ErrorApiMessage
      title={t('apiMessage.serverError.errorOccurred')}
      subtitle={t('apiMessage.serverError.tryAgainLater')}
      className="add-patient-api-message"
    />
  );

  return (
    <Modal
      header={t('patientList.addPatient')}
      opened={opened}
      onClose={() => {
        setIsBackendError(false);
        reset();
        onClose();
      }}
      testId={testId}
      hasError={isBackendError}
      errorMessage={t('patientList.addPatientModal.backendErrorMessage')}
    >
      {isLoadingDoctors ? (
        <ActivityIndicator />
      ) : isErrorFetchingDoctors ? (
        errorMessage
      ) : (
        <div className="add-patient-modal-content">
          <div className="add-patient-modal-fields">
            <div className="add-patient-section-container">
              <p className="add-patient-section-title">
                {t('patientList.addPatientModal.patientInformation.title')}
              </p>
              <div className="add-patient-section-inputs-container">
                <div className="add-patient-inputs-in-row-arrangement-container">
                  {lastnameInput}
                  {firstnameInput}
                </div>
                <div className="add-patient-inputs-in-row-arrangement-container">
                  {sexInput}
                  {birthYearInput}
                </div>
                {socialSecurityNumberInput}
                {emailInput}
              </div>
            </div>
            <div className="add-patient-section-container">
              <p className="add-patient-section-title">
                {t('patientList.addPatientModal.patientFollowup.title')}
              </p>
              <div className="add-patient-inputs-in-row-arrangement-container">
                {firstVisitDateInput}
                {referringDoctorInput}
              </div>
            </div>
          </div>
          {saveButton}
        </div>
      )}
    </Modal>
  );
};

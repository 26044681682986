import './Navbar.scss';

// eslint-disable-next-line no-restricted-imports
import { Navbar as MantineNavbar } from '@mantine/core';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { IS_APP_QOLIBRI } from '../../../../application';
import { ChartLine, FolderMedical, UserSquare } from '../../../../components';
import { Icon } from '../../../../components/icons/types';
import { COLORS } from '../../../../theme/colors';
import { PatientDetailsPageName } from '../../types';

interface NavbarProps {
  pageName: PatientDetailsPageName;
}

const pageNameToIconMapping: Record<PatientDetailsPageName, Icon> = {
  ['followup']: UserSquare,
  ['clinical-file']: FolderMedical,
  ['characterization']: ChartLine,
};

export const Navbar: FunctionComponent<NavbarProps> = ({
  pageName: selectedPageName,
}) => {
  const location = useLocation();

  const orderedPageNames: PatientDetailsPageName[] = IS_APP_QOLIBRI
    ? ['followup', 'characterization', 'clinical-file']
    : ['followup', 'clinical-file', 'characterization'];

  const icons = orderedPageNames.map((pageName) => {
    const isSelected = pageName === selectedPageName;
    const Icon = pageNameToIconMapping[pageName];

    return (
      <Link
        to={location.pathname.replace(selectedPageName, pageName)}
        key={pageName}
      >
        <div
          className={`icon-container ${
            isSelected ? 'icon-container-selected' : ''
          }`}
        >
          <Icon width={24} height={24} color={COLORS.white} />
        </div>
      </Link>
    );
  });

  return <MantineNavbar width={{ base: 56 }}>{icons}</MantineNavbar>;
};
